(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/configs/assets/javascripts/common.js') >= 0) return;  svs.modules.push('/components/sportinfo/configs/assets/javascripts/common.js');
"use strict";


const newEnetpulseWidgets = svs.core.detect.feature('ft-enetpulse-new');
const {
  matchState: {
    SportinfoMatchState: MatchState
  },
  constants: {
    SportinfoPathNames,
    SportinfoSportradarWidgets: SportradarWidgets,
    EnetPulseViewType,
    EnetpulseColorThemes,
    SvsViewType
  }
} = svs.components.sportinfo.common;
const {
  ContentProvider,
  EnetpulseWidgets
} = svs.components.sportinfo.common.constants;
const {
  ConfigBuilder
} = svs.components.sportinfo.configBuilder;
const {
  menuItems,
  content
} = new ConfigBuilder().addTab(SportinfoPathNames.MatchInfo, tab => {
  tab.setAll([{
    type: SvsViewType.MatchInfo,
    provider: ContentProvider.Svs
  }]).prepend(MatchState.Ongoing, [{
    type: SportradarWidgets.LiveMatchTracker,
    provider: ContentProvider.SportRadar
  }]);
}).addTab(SportinfoPathNames.Statistic, tab => {
  tab.set(MatchState.NotStarted, [{
    type: SportradarWidgets.HeadToHead,
    provider: ContentProvider.SportRadar,
    viewProps: {
      components: ['form', 'lastmatches']
    }
  }]).setMany([MatchState.Ongoing, MatchState.Finished], [{
    type: SportradarWidgets.Scoreboard,
    provider: ContentProvider.SportRadar,
    title: 'Matchhändelser',
    viewProps: {
      disableWidgetHeader: true,
      disableMatchInfo: true,
      expandedGoalScorers: true
    }
  }, {
    type: SportradarWidgets.GeneralStatistics,
    provider: ContentProvider.SportRadar,
    title: 'Livestatistik'
  }]);
}).addTab(SportinfoPathNames.Mutuals, tab => {
  tab.setMany([MatchState.Ongoing, MatchState.NotStarted], [{
    type: SportradarWidgets.MatchPreview,
    provider: ContentProvider.SportRadar,
    viewProps: {
      disableMoreStats: true,
      disableExpandButton: true
    }
  }]);
}).addTab(SportinfoPathNames.News, tab => {
  tab.setAll([{
    type: SvsViewType.News,
    provider: ContentProvider.Svs
  }]);
}).addTab(SportinfoPathNames.Table, tab => {
  tab.setAll([{
    type: SportradarWidgets.LiveTable,
    provider: ContentProvider.SportRadar,
    viewProps: {
      showOnlySelectedGroup: true
    }
  }]);
}).build();
const {
  menuItems: enetpulseMenuItems,
  content: enetpulseContent
} = new ConfigBuilder().addTab(SportinfoPathNames.MatchInfo, tab => {
  tab.setAll([{
    type: SvsViewType.MatchInfo,
    provider: ContentProvider.Svs
  }]).prepend(MatchState.Ongoing, [{
    type: SportradarWidgets.LiveMatchTracker,
    provider: ContentProvider.SportRadar
  }]);
}).addTab(SportinfoPathNames.Statistic, tab => {
  tab.set(MatchState.NotStarted, [{
    type: EnetPulseViewType.HeadToHead,
    provider: ContentProvider.EnetPulse,
    widgetId: EnetpulseWidgets.HeadToHead,
    viewProps: {
      options: {
        wv: 4,
        lng: 'sv',
        el_limit: 5,
        sh_h2h_s_f: 'yes',
        h2h_sh_m: 'yes',
        css_colors_theme: EnetpulseColorThemes.TipsenH2HLive
      }
    }
  }]).setMany([MatchState.Ongoing, MatchState.Finished], [{
    type: EnetPulseViewType.MatchIncidents,
    provider: ContentProvider.EnetPulse,
    title: 'Matchhändelser',
    widgetId: EnetpulseWidgets.MatchIncidents
  }]);
}).addTab(SportinfoPathNames.News, tab => {
  tab.setAll([{
    type: SvsViewType.News,
    provider: ContentProvider.Svs
  }]);
}).addTab(SportinfoPathNames.Table, tab => {
  tab.setAll([{
    type: EnetPulseViewType.TournamentStandings,
    provider: ContentProvider.EnetPulse,
    widgetId: EnetpulseWidgets.TournamentStandings,
    viewProps: {
      options: {
        lng: 'sv',
        sh_ha_d: 'tabs'
      }
    }
  }, {
    type: EnetPulseViewType.DrawStandings,
    provider: ContentProvider.EnetPulse,
    widgetId: EnetpulseWidgets.DrawStandings,
    viewProps: {
      options: {
        lng: 'sv',
        d_sh_rn_nav_alt_a: 'yes',
        d_sh_ts: 'yes'
      }
    }
  }]);
}).build();
setGlobal('svs.components.sportinfo.configs.sportConfigs', {
  commonConfig: newEnetpulseWidgets ? {
    menuItems: enetpulseMenuItems,
    content: enetpulseContent,
    config: 'common' 
  } : {
    menuItems,
    content,
    config: 'common' 
  }
});

 })(window);