(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/configs/assets/javascripts/overrides/providers/swehockey.js') >= 0) return;  svs.modules.push('/components/sportinfo/configs/assets/javascripts/overrides/providers/swehockey.js');
"use strict";


const newEnetpulseWidgets = svs.core.detect.feature('ft-enetpulse-new');
const {
  matchState: {
    SportinfoMatchState: MatchState
  },
  constants: {
    SportinfoPathNames,
    SportinfoSportradarWidgets: SportradarWidgets,
    SvsViewType,
    SweHockeyWidgetTypes
  }
} = svs.components.sportinfo.common;
const {
  ContentProvider
} = svs.components.sportinfo.common.constants;
const {
  ConfigBuilder
} = svs.components.sportinfo.configBuilder;
const {
  menuItems,
  content,
  defaultRoutes
} = new ConfigBuilder().addTab(SportinfoPathNames.MatchInfo, tab => {
  tab.setAll([{
    type: SvsViewType.MatchInfo,
    provider: ContentProvider.Svs,
    viewProps: {
      renderGeneralMatchInfo: false
    }
  }]).prepend(MatchState.Ongoing, [{
    type: SportradarWidgets.LiveMatchTracker,
    provider: ContentProvider.SportRadar
  }]).appendAll([{
    type: SweHockeyWidgetTypes.MatchGeneralInfo,
    provider: ContentProvider.SweHockey
  }]).appendMany([MatchState.Ongoing, MatchState.Finished], [{
    type: SweHockeyWidgetTypes.TeamCaptain,
    provider: ContentProvider.SweHockey
  }]);
}).addTab(SportinfoPathNames.Statistic, tab => {
  tab.set(MatchState.NotStarted, [{
    type: SweHockeyWidgetTypes.GamePreview,
    provider: ContentProvider.SweHockey
  }]).setMany([MatchState.Ongoing, MatchState.Finished], [{
    type: SweHockeyWidgetTypes.MatchStatus,
    provider: ContentProvider.SweHockey,
    viewProps: {
      shouldRegisterSocket: true
    }
  }]).appendMany([MatchState.Ongoing, MatchState.Finished], [{
    type: SweHockeyWidgetTypes.TotalStatistics,
    provider: ContentProvider.SweHockey,
    viewProps: {
      shouldRegisterSocket: true
    }
  }, {
    type: SweHockeyWidgetTypes.PeriodStatistics,
    provider: ContentProvider.SweHockey,
    viewProps: {
      shouldRegisterSocket: true
    }
  }]);
}).addTab(SportinfoPathNames.Events, tab => {
  tab.setMany([MatchState.Ongoing, MatchState.Finished], [{
    type: SweHockeyWidgetTypes.Events,
    provider: ContentProvider.SweHockey,
    viewProps: {
      shouldRegisterSocket: true
    }
  }]);
}).addTab(SportinfoPathNames.News, tab => {
  tab.setAll([{
    type: SvsViewType.News,
    provider: ContentProvider.Svs
  }]);
}).addTab(SportinfoPathNames.Table, tab => {
  tab.setAll([{
    type: SweHockeyWidgetTypes.LiveTableDropdown,
    provider: ContentProvider.SweHockey,
    viewProps: {
      shouldRegisterSocket: true
    }
  }]);
}).addTab(SportinfoPathNames.Lineup, tab => {
  tab.setAll([{
    type: SweHockeyWidgetTypes.Lineup,
    provider: ContentProvider.SweHockey
  }]);
}).setDefaultRoutes(SportinfoPathNames.Events, [MatchState.Ongoing, MatchState.Finished]).build();
const {
  menuItems: enetpulseMenuItems,
  content: enetpulseContent,
  defaultRoutes: enetpulseDefaultRoutes
} = new ConfigBuilder().addTab(SportinfoPathNames.MatchInfo, tab => {
  tab.setAll([{
    type: SvsViewType.MatchInfo,
    provider: ContentProvider.Svs,
    viewProps: {
      renderGeneralMatchInfo: false
    }
  }]).prepend(MatchState.Ongoing, [{
    type: SportradarWidgets.LiveMatchTracker,
    provider: ContentProvider.SportRadar
  }]).appendAll([{
    type: SweHockeyWidgetTypes.MatchGeneralInfo,
    provider: ContentProvider.SweHockey
  }]).appendMany([MatchState.Ongoing, MatchState.Finished], [{
    type: SweHockeyWidgetTypes.TeamCaptain,
    provider: ContentProvider.SweHockey
  }]);
}).addTab(SportinfoPathNames.Statistic, tab => {
  tab.set(MatchState.NotStarted, [{
    type: SweHockeyWidgetTypes.GamePreview,
    provider: ContentProvider.SweHockey
  }]).setMany([MatchState.Ongoing, MatchState.Finished], [{
    type: SweHockeyWidgetTypes.MatchStatus,
    provider: ContentProvider.SweHockey,
    viewProps: {
      shouldRegisterSocket: true
    }
  }]).appendMany([MatchState.Ongoing, MatchState.Finished], [{
    type: SweHockeyWidgetTypes.TotalStatistics,
    provider: ContentProvider.SweHockey,
    viewProps: {
      shouldRegisterSocket: true
    }
  }, {
    type: SweHockeyWidgetTypes.PeriodStatistics,
    provider: ContentProvider.SweHockey,
    viewProps: {
      shouldRegisterSocket: true
    }
  }]);
}).addTab(SportinfoPathNames.Events, tab => {
  tab.setMany([MatchState.Ongoing, MatchState.Finished], [{
    type: SweHockeyWidgetTypes.Events,
    provider: ContentProvider.SweHockey,
    viewProps: {
      shouldRegisterSocket: true
    }
  }]);
}).addTab(SportinfoPathNames.News, tab => {
  tab.setAll([{
    type: SvsViewType.News,
    provider: ContentProvider.Svs
  }]);
}).addTab(SportinfoPathNames.Table, tab => {
  tab.setAll([{
    type: SweHockeyWidgetTypes.LiveTableDropdown,
    provider: ContentProvider.SweHockey,
    viewProps: {
      shouldRegisterSocket: true
    }
  }]);
}).addTab(SportinfoPathNames.Lineup, tab => {
  tab.setAll([{
    type: SweHockeyWidgetTypes.Lineup,
    provider: ContentProvider.SweHockey
  }]);
}).setDefaultRoutes(SportinfoPathNames.Events, [MatchState.Ongoing, MatchState.Finished]).build();
setGlobal('svs.components.sportinfo.configs.sportConfigs.providerOverrides', {
  sweHockeyConfig: newEnetpulseWidgets ? {
    menuItems: enetpulseMenuItems,
    content: enetpulseContent,
    defaultRoutes: enetpulseDefaultRoutes,
    config: 'swehockey' 
  } : {
    menuItems,
    content,
    defaultRoutes,
    config: 'swehockey' 
  }
});

 })(window);